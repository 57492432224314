<template>
    <div class="h-screen bg-blue-300 flex align-items-center justify-content-around">
        <div v-show="loadingIndex > 0" class="w-full">
            <div class="flex align-content-around w-full">
                <ProgressSpinner/>
            </div>
        </div>

        <div class="w-full" style="max-width: 32rem;" v-show="loadingIndex === 0">
            <div class="flex flex-wrap align-items-stretch">
                <div class="w-full p-6 pb-3 surface-card bg-white border-1 surface-border">
                    <div class="flex flex-wrap align-items-center h-full">
                        <div class="w-full">
                            <img
                                src="./../assets/img/logo.jpg"
                                style="width: 100%;"
                                class="mb-6"
                                alt=""
                            />

                            <router-view class="w-full"/>

                            <ul class="list-none block text-center mx-0 px-0 w-full">
                                <li class="inline-block w-full"
                                    v-for="(name, routePath) in links"
                                    v-bind:key="routePath"
                                >
                                    <router-link
                                        v-if="routePath !== $route.path"
                                        :to="routePath"
                                    >
                                        <Button
                                            class="p-button p-button-text cursor-pointer w-full"
                                            :label="name"
                                        />
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center mt-4">
                <Dropdown
                    class="w-12rem text-left"
                    v-model="$i18n.locale"
                    :options="[{id: 'de', label: $t('German')}, {id: 'en', label: $t('English')}]"
                    option-value="id"
                    option-label="label"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import ProgressSpinner from 'primevue/progressspinner';
    import Button from 'primevue/button';
    import Dropdown from "primevue/dropdown";

    export default {
        components: {
            ProgressSpinner,
            Dropdown,
            Button
        },
        computed: {
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            },
            links() {
                return {
                    '/login': this.$t('Back to login'),
                    '/password-forget': this.$t('Password forget'),
                }
            }
        },
        mounted() {
            this.$i18n.locale = 'de';
            if (this.$route.name === 'Security') {
                this.$router.push('/surveys');
            }
        },
    }
</script>
